@tailwind base;
@tailwind components;
@tailwind utilities;

.labelForm {

    font-size: 14px;

    color: #000000;

    line-height: 22px;

}

.editor h1,
.ck-content h1{
    font-size: 34px;
}
.editor h2,
.ck-content h2{
    font-size: 28px;
}
.editor h3,
.ck-content h3{
    font-size: 24px;
}
.editor,
.ck-content{
    font-size: 18px;
}

table{
    border: 1px solid black;
    margin: 10px 0;
}
td{
    border: 1px solid black;
    padding: 10px;
}

ol{
    font-size: 14px;
    margin:  0 30px;
    list-style-type:decimal;
}

ul{
    font-size: 14px;
    margin:  0 35px;
    list-style-type:disc;
}